
import { IonPage, IonButton, IonContent, IonGrid, IonRow, IonCol, IonIcon, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

import BaseCard from '@/components/Card/BaseCard.vue';
import userFull from '../../resources/icons/user-square.svg';
import bigPicture from '../../resources/icons/bigPicture.svg';
import { TokenService } from '@/services';
import { REMOVE_CURRENT_USER } from '@/store/actions.type';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'DischargedPage',
  components: {
    BaseCard,
    IonContent,
    IonPage,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonFooter
  },
  props: {
    dischargedReason: {
      type: String,
      required: true
    }
  },
  setup() {
    const router = useRouter();
    const tokenService = new TokenService();
    const store = useStore();

    const logout = () => {
      tokenService.removeToken();
      store.dispatch(REMOVE_CURRENT_USER);
      router.replace('/login');
    };

    const pageTitle = 'Virtual Ward';
    return {
      pageTitle,
      userFull,
      bigPicture,
      logout
    };
  }
});
